.job-input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    box-shadow: inset 0 0 2px rgba(0,0,0,.15);
    font-size: 16px;
    color: #3c318f;
    margin-bottom: 30px;
    font-family: inherit;
}

.job-input:focus {
    border: 1px solid rgba(0,0,0,.5);
}

.job-input-file-supported-formats {
    color: $grayLight;
    font-size: 0.8em;
    margin-top: 5px;
    margin-bottom: 30px;
}

label {
    display: block;
    margin-bottom: 10px;
    text-decoration: underline;
}

.job-form-headline {
    font-size: 120%;
}

.asterisk {
    font-size: 18px;
    line-height: 18px;
    text-align: right;
    color: rgb(219, 42, 58);
}

#btnSubmitJobApplicationForm {
    margin: 30px 0;
    font-size: 20px;
    font-family: inherit;
}