h2 {
    margin-bottom: 2em;
}

.presse-header {
    background: $brand-primary;
    color: $white;

    margin-bottom: 2em;


    .badge {
        float: left;
    }


    @include media(">=tablet") {
        margin-bottom: 70px;

        .badge {
            float: right;
        }
    }

    h1 {
        max-width: 430px;
    }

    @extend %clearfix;
    .title {
        float: left;
        margin-bottom: 1em;
    }

}

.press-box {
    display: block;
    background: $white;
    border: 1px solid #ededed;
    border-radius: 5px;
    margin-bottom: 30px;
    box-shadow: 0px 5px 0px 0px #ededed;
    padding: 25px;
    color: $brand-primary;
    text-decoration: none;
    transition: all .185s ease-in-out;

    &:hover {
        transform: translateY(-2px) scale(1.02);
        color: $brand-primary;
        text-decoration: none;
    }

    span.title {
        font-weight: $font-weight-bold;
        display: block;
        margin-bottom: .5em;
    }

    span.source {
        &::before {
            content: '– ';
        }
        color: $gray-light;
        // text-transform: uppercase;
    }

    @include media(">=tablet") {
        height: 170px;
    }

    @include media(">=desktop") {
        height: 160px;
    }
}

.presse-links {
    margin-bottom: 2em;

    @include media(">=tablet") {
        margin-bottom: 3em;
    }
}

.presse-about {

    .col-4 {
        margin-bottom: 2em;
    }

    .col-2 {
        margin-bottom: 2em;

        .btn {
            margin-bottom: 1em;
        }
    }

    @include media(">=tablet") {
        margin-bottom: 3em;
    }
}