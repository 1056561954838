.confirmation-body {
  background-color: #f6f6f6;
}

#confirmation {
  max-width: 500px;
  margin: 0 auto;

  .hint {

  }

  .confirmation-image {
    display: block;
    margin: 1em auto 3em;
    text-align: center;
    
    img {
      display: inline-block;
    }
  }
}

#confirmation-error-code {
  font-size: 0.8em;
  display: inline-block;
  margin-top: 1.5em;
  color: $grayLight;
}