//main: style.scss

.button-group-a {
  .button-group-b {
    display: none;
  }
}

.button-group-b {
  .button-group-a {
    display: none;
  }
}
.flex-child {
  // display: none;
  img {
    max-width: 100%;
    height: auto;
  }
}

.mini-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .mini-child {
    width: 100%;
    flex-basis: 100%;
  }
}

@include media("<tablet") {
  .flex-child {
    margin-bottom: calc($sectionSpacerVertical / 2);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .flex-child-center {
    text-align: center;
  }

  .hide-smartphone-down {
    display: none;
  }
}

// global column system for equal sized columns
@include media(">=tablet") {
  .flex-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    margin-left: -(calc($colSpacingTablet / 2));
    margin-right: -(calc($colSpacingTablet / 2));
  }

  .flex-child {
    flex-basis: 100%;
    order: 1;
    padding-left: calc($colSpacingTablet / 2);
    padding-right: calc($colSpacingTablet / 2);
  }

  .flex-order-2 {
    order: 2;
  }

  .flex-order-3 {
    order: 3;
  }

  .flex-child-center {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
  }

  .flex-child-va-center {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .hide-tablet-up {
    display: none !important;
  }
}

@include media(">=desktop") {
  .flex-columns {
    margin-left: -(calc($colSpacingDesktop / 2));
    margin-right: -(calc($colSpacingDesktop / 2));

    .flex-child {
      padding-left: calc($colSpacingDesktop / 2);
      padding-right: calc($colSpacingDesktop / 2);
    }
  }
}

// General section styling
.front-section {
  padding-top: 35px;
  padding-bottom: 50px;

  .legal-notice {
    font-size: $fontSizeSmallMobile;
    line-height: 1.3em;
    color: $brand-primary-light;
    // opacity: .75;
  }

  @include media("<tablet") {
    .text-wrapper {
      max-width: 480px;
    }

    border-bottom: 8px solid #f2f2f2;

    .flex-columns {
      max-width: 480px;
      margin: 0 auto;
    }

    &#recoms {
      .flex-columns {
        max-width: none;
      }

      .text-wrapper {
        margin: 0 auto;
      }
    }
  }

  @include media(">=570px") {
    padding-bottom: 70px;
  }

  @include media(">=tablet") {
    padding-top: 70px;
    padding-bottom: 50px;
  }

  @include media(">=desktop") {
    padding-top: 100px;
    padding-bottom: 60px;
  }

  h2 {
    @extend .Frontpage-Heading;
    line-height: 1.3em;
    margin-top: 0;
    margin-bottom: 15px;

    &.smaller {
      font-size: 32px;

      @include media("<500px") {
        font-size: 28px;
      }
    }
  }

  h4 {
    font-size: 22px;
    flex-wrap: $font-weight-regular;
    margin-bottom: 0.3em;
  }

  p {
    font-size: $fontSizeMobile;

    @include media(">=tablet") {
      font-size: $fontSizeDesktop;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &.small {
      font-size: $fontSizeSmallMobile;

      @include media(">=tablet") {
        font-size: $fontSizeSmallDesktop;
      }
    }
  }
}

// Individual section styling
#guru-col {
  @include media("<tablet") {
    display: flex;
    justify-content: center;
  }
  @include media(">=tablet") {
    width: 50%;
    flex-basis: 50%;
  }
}
/*
for A/B Testing purposes
 */
#front-test-b {
  @include media(">=tablet") {
    margin-top: -6%;
  }
  .no-test-screen & {
    display: none;
  }
}

#guru-wrapper {
  .test-screen & {
    display: none;
  }
}
#welcome {
  padding-top: 0;
  position: relative;
  z-index: 20;
  overflow: hidden;

  /**
  A/B Testing purposes
 */
  @include media("<tablet") {
    .test-screen & {
      .flex-columns {
        display: flex;
        flex-direction: column;

        .flex-order-2 {
          order: 2;
        }
        .flex-order-2 + div {
          order: 1;
          margin-top: 30px;
        }
      }
    }
  }
  /**
  end of testing purposes
   */

  @include media(">=tablet") {
    background-position: 50% 120%;

    .flex-child {
      max-width: 50%;
    }
  }

  @include media(">=desktop") {
    background-position: 53% 110%;
  }

  .container {
    z-index: 30;
    position: relative;
  }

  @include media(">=desktop") {
    margin-bottom: 0;
  }

  .flex-child {
    margin-bottom: 0;
  }

  h1 {
    position: relative;

    .has-js & {
      span {
        display: block;
      }
    }
  }

  @include media("<tablet") {
    text-align: center;
    .test-screen & {
      text-align: left;
    }
  }

  h1 {
    font-size: 30px;
    margin-bottom: 1rem;

    @include media(">=375px") {
      font-size: 36px;
    }

    @include media(">=414px") {
      font-size: 40px;
      max-width: 390px;
      display: inline-block;
    }

    @include media(">=620px") {
      font-size: 48px;
      max-width: 460px;
    }

    @include media(">=tablet") {
      font-size: 40px;
      margin-bottom: 25px;
    }

    @include media(">=880px") {
      font-size: 48px;
    }

    @include media(">=desktop") {
      font-size: 48px;
      font-weight: $font-weight-bold;
    }
  }

  @include media("<tablet") {
    p {
      max-width: 490px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .front-hero-text {
    .dhdl-container {
      background: $white;
      border-radius: 13px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      display: inline-block;
      font-size: 16px;
      color: $grayDarker;
      padding: 15px 15px 15px 15px;
      line-height: 1.4em;

      .dhdl-badge {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        flex-direction: row;
      }

      .emoji {
        font-size: 30px;
        margin-right: 7px;
      }

      span {
        display: block;
      }

      @include media("<tablet") {
        margin-bottom: 30px;
        margin-top: -30px;
      }

      @include media(">=tablet") {
        margin-bottom: 35px;
        margin-top: 0px;
      }

      @include media(">=900px") {
        margin-top: -90px;
      }
    }

    .btn-group {
      margin-bottom: 0;

      .btn {
        margin-bottom: 2em;
      }

      .test-screen & {
        margin-bottom: 20px;

        .btn {
          margin-bottom: 20px;
          margin-left: 0;
        }
      }
    }

    p {
      margin-bottom: 30px;

      &.no-ios {
        .is-iphone & {
          display: none;
        }
      }

      &.is-ios {
        .no-iphone & {
          display: none;
        }

        .is-iphone & {
          display: block !important;
        }
      }

      .btn-secondary {
        margin-bottom: 1.5em;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// SVG
#guru-wrapper {
  max-width: 100%;
  width: 400px;
  margin: 0 auto;
  margin-top: -20px;

  @include media(">=375px") {
    max-width: 90%;
  }

  @include media(">=414px") {
    max-width: 80%;
  }

  @include media(">=tablet") {
    max-width: 100%;
    margin-top: -70px;
  }
}

#guru-container {
  display: block;
  width: 100%;
  padding-bottom: 95%;
  height: 0;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

.business-award {
  display: block;
  min-height: 60px;
  font-size: 16px;
  color: $brand-secondary-shadow;
  max-width: 250px;
  margin: 0 auto;
  padding-top: 20px;

  img {
    float: left;
    margin-right: 10px;
    margin-top: -4px;
  }
}

#press-features {
  background: $grayLightest;
  border-top: 1px solid $grayLighter;
  border-bottom: 1px solid $grayLighter;
  text-align: center;
  padding: 30px $colSpacingTablet;
}

#multibanking {
  @include media(">=tablet") {
    margin-top: -60px;
  }

  @include media(">=desktop") {
    margin-top: -110px;
  }

  .small {
    font-size: 16px;
    color: #a9a9a9;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    margin-bottom: 15px;

    img {
      padding-top: 15px;
    }
  }
}

#mai {
  @include media("<tablet") {
    .flex-child:first-child {
      margin-bottom: 0;
    }
  }
  @include media(">=desktop") {
    padding: 0;
    margin-top: -45px;
  }
}

#futuretimeline {
  @include media("<tablet") {
    .flex-child:first-child {
      margin-bottom: 0;
    }
  }

  @include media(">=tablet") {
    margin-top: -15px;
    padding: 0;
  }

  @include media(">=desktop") {
    margin-top: -45px;
  }

  .screen {
    overflow: hidden;
  }

  @include media(">=desktop") {
    margin-top: -45px;
  }
}

#pfm {
  padding: 10px 0 50px;

  @include media("<tablet") {
    .flex-child:first-child {
      margin-bottom: 0;
    }
  }

  @include media(">=tablet") {
    margin-top: -15px;
  }

  @include media(">=desktop") {
    margin-top: -45px;
  }
  .flex-child-va-center {
    position: relative;
    z-index: 10;
  }
}

#contracts {
  padding: 10px 0 10px;

  @include media("<tablet") {
    .flex-child:first-child {
      margin-bottom: 0;
    }
  }

  @include media(">=tablet") {
    margin-top: -15px;
  }

  @include media(">=desktop") {
    margin-top: -45px;
  }
  .flex-child-va-center {
    position: relative;
    z-index: 10;
  }
}

#fakeshop {
  .label-accent {
    color: #AB20FE;
    background-color: rgba(171, 32, 254, 0.1);
    border-radius: 12px;
    width: max-content;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  padding: 10px 0 10px;

  @include media("<tablet") {
    .flex-child:first-child {
      margin-bottom: 0;
    }
  }

  @include media(">=tablet") {
    margin-top: -15px;
  }

  @include media(">=desktop") {
    margin-top: -45px;
  }
  .flex-child-va-center {
    position: relative;
    z-index: 10;
  }
}

.front-page-phone-size {
  width: 100%;
  max-width: 420px;

  @include media("<tablet") {
    margin: 0 auto;
  }
}

@include media(">=tablet") {
  // #flex-slider {
  //   max-width: 50%;
  // }

  #recoms .flex-child {
    max-width: 50%;
  }
}

#recoms {
  background: $grayLightest;

  // create slider container
  #card-slider-fullwidth {
    display: block;

    @include media("<tablet") {
      margin-left: -30px;
      margin-right: -30px;
      overflow: hidden;
      height: 394px;

      &.bounceLeft {
        animation: bounceLeft 0.225s ease-in-out;
      }

      &.bounceRight {
        animation: bounceRight 0.225s ease-in-out;
      }
    }

    @include media(">=tablet") {
      width: 100%;
      position: relative;
      padding-bottom: 20px;

      #sl-nav {
        position: absolute;
        bottom: -55px;
        left: 0;
        right: 0;
        text-align: center;
      }

      .arrow {
        display: inline-block;
        height: 45px;
        width: 45px;
        border-radius: 100%;
        border: 3px solid $brand-primary;
        cursor: pointer;
        margin: 0 10px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        background-image: url("../img/arrow-left.svg");
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 45% 50%;

        &#sr {
          transform: rotate(180deg);
        }
      }
    }

    @include media(">=1260px") {
      padding-bottom: 0;

      #sl-nav {
        top: 50%;
        bottom: auto;
        margin-top: -23px;
        z-index: 40;
      }

      .arrow {
        position: absolute;
        left: -40px;

        &#sr {
          left: auto;
          right: -40px;
        }
      }
    }
  }

  // create mobile slider
  @include media("<tablet") {
    #slider {
      // background: rgba(0,0,0,.1);
      height: 394px;
      position: relative;
      transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1);
      transform: translateX(0);

      .no-js & {
        .card-holder {
          display: none;

          &:first-child {
            display: block;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      .has-js & {
        .card-holder {
          display: block;
          float: left;
          margin: 0 calc((100vw - 290px) / 4 - 10px);

          &:first-child {
            margin-left: calc((100vw - 290px) / 2);
          }

          &:last-child {
            margin-right: calc((100vw - 290px) / 2);
          }
        }
      }
    }
  }

  @include media("<375px") {
    .has-js & {
      #slider .card-holder img {
        transform: scale(0.94);
      }
    }
  }

  // create desktop slider
  @include media(">=tablet") {
    padding-top: $sectionSpacerDesktop;
    padding-bottom: $sectionSpacerDesktop;

    #slider {
      position: relative;
      width: 100% !important;
      display: block;
      height: 394px;
      transform: none !important;

      .card-holder {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease-in-out;
        max-width: 290px;
        width: 90%;
        background: $grayLightest;

        img {
          transition: all 0.3s ease-in-out;
        }

        &.center {
          z-index: 50;
          margin-left: 0;
        }

        &.behind {
          z-index: 30;
          margin-left: calc((18% / 2) * -1);
          transform: translateX(-50%) scale(0.9);

          img {
            opacity: 0.6;
          }

          &.right {
            margin-left: calc((18% / 2));
          }
        }

        &.behinder {
          z-index: 10;
          margin-left: calc((35% / 2) * -1);
          transform: translateX(-50%) scale(0.8);

          img {
            opacity: 0.3;
          }

          &.right {
            margin-left: calc((35% / 2));
          }
        }
      }
    }
  }

  // @include media(">=tablet", "<980px") {
  //   #slider .card-holder {
  //     &.behind {
  //       margin-left: calc((20% / 2) * -1);

  //       &.right {
  //       margin-left: calc((20% / 2));
  //       }
  //     }

  //     &.behinder {
  //       margin-left: calc((40% / 2) * -1);

  //       &.right {
  //         margin-left: calc((40% / 2) );
  //       }
  //     }
  //   }
  // }
}

// improved cursors for everybody
.touch_mode_grab {
  cursor: grab !important;
  cursor: -moz-grab !important !important;
  cursor: -webkit-grab !important;
  cursor: url("../img/openhand.cur"), n-resize; /* standard */
  cursor: url("../img/openhand.cur"), n-resize \9; /* IE 8 and below */
  *cursor: url("../img/openhand.cur"), n-resize; /* IE 7 and below */
  _cursor: url("../img/openhand.cur"), n-resize; /* IE 6 */
}

.touch_mode_grabbing {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
  cursor: url("../img/closedhand.cur"), n-resize; /* standard */
  cursor: url("../img/closedhand.cur"), n-resize \9; /* IE 8 and below */
  *cursor: url("../img/closedhand.cur"), n-resize; /* IE 7 and below */
  _cursor: url("../img/closedhand.cur"), n-resize; /* IE 6 */
}

@keyframes bounceLeft {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes bounceRight {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-15px);
  }
  100% {
    transform: translateX(0px);
  }
}

#mission {
  background: $brand-primary;
  color: $white;
  border-bottom: 0 none;

  a {
    color: $brand-secondary;
    text-decoration: none;
    display: inline-block;
    padding: 5px 25px 5px 0;
    position: relative;

    &::after {
      content: " ›";
    }

    &:last-of-type {
      margin-bottom: -5px;
    }

    &:hover {
      color: $brand-secondary-shadow;
      text-decoration: none;
    }
  }

  .small {
    font-size: 27px;
  }

  #counter-wrapper {
    p {
      line-height: 1em;
      max-width: 380px;
      margin-bottom: 0.5em;
    }
  }

  #counter-container {
    margin-bottom: 1em;
    overflow: hidden;
  }
}

#counter {
  // define boxes
  $maxWidth: 100%;
  $boxHeight: 1.3em;
  $border-width: 1px;
  $border-radius: 6px;
  $spacing: 0.11em;
  $animationDuration: 0.375s;

  // only negative numbers allowed, if the font-size has a vertical offset
  $fontOffset: -0.15em;

  // font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

  // define font
  font-size: 2em;
  font-size: 9vw;
  font-weight: $font-weight-bold;
  color: $brand-secondary;

  @include media(">=tablet") {
    font-size: 5vw;
  }

  @include media(">=desktop") {
    font-size: 55px;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    width: $maxWidth;
    margin-left: -(calc($spacing / 2));
    margin-right: -(calc($spacing / 2));
    position: relative;

    &::after {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 2px;
      margin-top: -1px;
      background: $brand-primary;
      z-index: 200;
      width: 100%;
    }
  }

  .number {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border: $border-width solid #212154;
    width: $maxWidth;
    overflow: hidden;
    z-index: 10;
    background: $brand-primary-shadow;
    transform: perspective(500px) rotateX(0deg);

    &.number-first {
      height: 50%;
      border-bottom: 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      transform-origin: bottom center;
      display: block;
      line-height: $boxHeight + $fontOffset;
      z-index: 15;
    }

    &.number-second {
      margin-top: calc($boxHeight / 2) + $fontOffset;
      line-height: 0 - $fontOffset;
      height: calc(50% - #{$fontOffset});
      border-top: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      transform-origin: top center;
      // padding-bottom: .3em;
    }

    &.number-new {
      &.number-second {
        z-index: 12;
        transform: rotateX(90deg);
      }
    }
  }

  .child {
    width: 100%;
    margin-left: calc($spacing / 2);
    margin-right: calc($spacing / 2);
    text-align: center;
    display: flex;
    height: $boxHeight;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: space-around;
    position: relative;

    &.animate {
      .number-current.number-first {
        animation: move-top-frame $animationDuration ease-in;
        transform: rotateX(90deg);
      }

      .number-new.number-second {
        animation: move-bottom-frame $animationDuration * 2 ease-out;
        transform: rotateX(0deg);
        // animation-delay: .5s;
      }

      .number-first {
        // transition: transform $animationDuration ease-in;
        // transition-delay: 0s;
      }

      .number-second {
        // transition: transform $animationDuration ease-out;
        // transition-delay: $animationDuration;
      }
    }
  }

  .point {
    width: 0.2em;
    background: none;
    color: $brand-secondary;
  }
}

@keyframes move-top-frame {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(90deg);
  }
}

@keyframes move-bottom-frame {
  0% {
    transform: rotateX(90deg);
  }
  50% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

#security {
  @include media("<tablet") {
    padding-top: 70px;
  }
  .flex-child-center img {
    @include media(">=tablet") {
      width: 100%;
      max-width: 320px;
    }
  }
}

#feedback {
  border-bottom: 0 none;

  .bounceLeft {
    animation: bounceLeft 0.225s ease-in-out;
  }

  .bounceRight {
    animation: bounceRight 0.225s ease-in-out;
  }

  h2 {
    text-align: center;
    margin-bottom: 1.2em;
  }

  .cta,
  .meta {
    text-align: center;
  }

  .meta {
    font-size: 1rem;
    margin-bottom: 30px;
    strong {
      font-size: 1.5em;
    }
  }

  .show375 {
    @include media("<375px") {
      display: none;
    }
  }

  .meta-sub {
    display: inline-block;

    &:last-child {
      margin-left: 10px;
    }
  }

  .mobile-feedback-container {
    margin-bottom: 30px;
  }

  .feedback {
    background-color: $grayLightest;
    border-radius: 9px;
    padding: 1em;
    color: $grayDarker;

    @include media("<tablet") {
      margin-bottom: 15px;
    }

    .rating {
      margin-bottom: 7px;

      .rating-stars,
      .stars-orange {
        width: 78px;
        height: 15px;
      }
    }

    p {
      margin: 0;
      font-size: 1em;
      line-height: 1.7em;
      @include media(">=tablet") {
        font-size: 16px;
        line-height: 1.6em;
      }
    }

    .text {
      margin-bottom: 9px;
    }

    .author {
      color: $grayLight;
      font-size: 14px;
    }
  }
}

$amountOfFeedbacks: 15;
$feedbackWidth: 314px;
$feedbackSpacer: 15px;
$feedbackContainerWidth: calc(
  #{$amountOfFeedbacks} * (#{$feedbackWidth} + #{$feedbackSpacer})
);

#feedback-slider {
  @include media("<tablet") {
    display: none;
  }
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;

  .feedback {
    width: $feedbackWidth;
    min-width: $feedbackWidth;
    margin-right: calc($feedbackSpacer / 2);
    margin-left: calc($feedbackSpacer / 2);
    opacity: 0.4;
    transition: opacity 0.375s ease-out;

    &.active {
      opacity: 1;
    }
  }
}

#feedback-controlls {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  user-select: none;

  @include media("<1116px") {
    .container {
      width: 100%;
      margin: 0;
      padding: 0;
      max-width: none;
    }
  }

  .button {
    background-color: $white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    height: 50px;
    width: 50px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.125s ease-out;

    span {
      font-size: 2em;
      margin-bottom: 25%;
    }

    &:hover {
      &.left {
        margin-left: 10px;
      }

      &.right {
        margin-right: 10px;
      }
    }

    &.left {
      float: left;
      margin-left: 15px;
    }

    &.right {
      float: right;
      margin-right: 15px;
    }

    @include media(">=1116px") {
      &.left {
        margin-left: -60px;
      }

      &.right {
        margin-right: -60px;
      }

      &:hover {
        &.left {
          margin-left: -65px;
        }

        &.right {
          margin-right: -65px;
        }
      }
    }
  }
}

#feedback-container {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: $feedbackContainerWidth;
  transform: translateX(-2467px) translateX(50vw);
  transition: all 375ms ease-in-out;
  user-select: none;
}

#get-section {
  .rating {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@include media("<tablet") {
  #get-section {
    background: $grayLightest;
    border-bottom: 3px solid $brand-primary;
    padding: 0;
    margin-bottom: 70px;
  }
}

// get the app box
.get-the-app {
  background: $grayLightest;
  // border-bottom: 3px solid $brand-primary;
  // padding: 30px;
  padding: $sectionSpacerVertical 0;
  text-align: center;

  @include media(">=tablet") {
    padding: 60px 30px;
    border-bottom: 3px solid $brand-primary;
  }

  h2 {
    margin-bottom: $sectionSpacerVertical;

    @include media(">=tablet") {
      margin-bottom: $sectionSpacerVertical;
    }
  }

  small {
    font-size: $fontSizeSmallMobile;
    line-height: 1.4em;
    display: block;
    margin-bottom: 1em;
  }

  .flex-child {
    text-align: left;
    margin-bottom: $sectionSpacerVertical;

    @include media("<tablet") {
      &:first-child {
        padding-bottom: $sectionSpacerVertical;
        border-bottom: 1px solid $grayLighter;
      }

      &:last-child {
        margin-bottom: calc($sectionSpacerVertical / 2);
      }
    }

    @include media(">=tablet") {
      &:first-child {
        border-right: 1px solid $grayLighter;
      }
    }
  }

  .mini-flex {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    .mini-child:first-child {
      flex-basis: 145px;
      width: 145px;
      max-width: 145px;
    }
  }

  .mini-child:last-child {
    padding-left: 30px;
  }

  @include media(">=tablet") {
    small {
      font-size: $fontSizeSmallDesktop;
    }

    .mini-child:first-child {
      text-align: right;
    }
  }
}

#get-section {
  margin-top: 0;
  padding-top: 0;
}

#get-notified {
  // font-size: 1.2em;
  // margin-bottom: calc($sectionSpacerVertical / 2);
  .btn {
    max-width: 160px;
    font-size: 16px;
    // padding: 8px 0;
    text-align: center;
  }
}

#front-skyline {
  margin: 0;
  padding: 0;
  line-height: 0;
  width: 100%;
  padding-bottom: 15%;
  background-image: url("../img/skyline.svg");
  background-position: 50% bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @include media("<tablet") {
    background-position: 60% bottom;
    padding-bottom: 120px;
  }

  @include media(">=desktop") {
    padding-bottom: 13%;
  }

  @include media(">=1672px") {
    background-size: contain;
    height: 211px;
    padding-bottom: 0;
    background-size: 2337px 250px;
    margin-top: 35px;
  }
}

// magnific animation
/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/**
A/B Test new content
 */
.front-store-buttons-wrapper {
  @include media("<tablet") {
    display: flex;
    justify-content: center;

    .test-screen & {
      justify-content: left;
    }
  }
}
.front-store-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  @include media("<tablet") {
    justify-content: center;
    flex-direction: column;

    .os-is-ios & {
      .android {
        display: none;
      }
    }

    .os-is-android & {
      .ios {
        display: none;
      }
    }
  }

  .store {
    display: flex;
    @include media("<tablet") {
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      .no-os-detected & {
        &:last-child {
          margin-bottom: 0px;
        }
      }

      .button {
        margin-right: 15px;
        height: 46px;
        text-align: left;

        .no-os-detected & {
          width: 148px;
        }
      }
    }

    @include media(">=tablet") {
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 30px;
      .button {
        order: 5;
        display: flex;
        height: 44px;
        transition: all 0.125s ease-in-out;

        &:hover {
          transform: scale(1.03);
        }
      }
      &.ios .button {
        margin-bottom: -1px;
        margin-top: 1px;
      }
      .rating {
        order: 1;
        margin-bottom: 10px;
      }
    }
  }

  .store-divider {
    display: none;

    @include media(">=tablet") {
      display: block;
      width: 1px;
      background: $grayLighter;
      margin: 0 5%;
    }
  }
}

.rating {
  .rating-stars-container {
    display: flex;
    flex: 1;

    strong {
      margin-top: -1px;
      display: inline-block;
      margin-left: 5px;
      color: $grayDark;
    }
  }

  .rating-stars {
    width: 94px;
    height: 18px;
    background-image: url("../img/ratingstars-gray.svg");
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;

    .stars-cut {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }

    .stars-orange {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 94px;
      z-index: 5;
      background-image: url("../img/ratingstars-orange.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .rating-meta {
    display: block;
    color: $grayDark;
    font-weight: $font-weight-regular;
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 0;
  }
}

.trust-wrapper {
  display: flex;
  flex-direction: row;
  height: 150px;
  width: 100%;
  gap: 15px;
  align-content: flex-start;
  justify-content: center;
}
