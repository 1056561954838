// main: style.scss

/**
- footer
- cookie alert
- update notice
 */

.footer {
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative;
  z-index: 50;
  background-color: $grayLightest;

  .footer-primary {
    margin-bottom: 20px;
  }

  @include media("<tablet") {
    ul {
      margin-bottom: 1.5em;
    }
  }

  @include media(">=tablet") {
    padding-top: 70px;

    .row-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    .logo-list {
      text-align: right;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a:hover img {
    opacity: 0.7;
  }

  a {
    color: $brand-primary;
    text-decoration: none;

    &:hover {
      color: $brand-primary-light;
    }
  }

  @include media("<tablet") {
    .logo-list img {
      margin-right: 14px;
      margin-bottom: 14px;
    }
  }

  @include media(">=tablet") {
    .logo-list {
      margin-right: -14px;
    }

    .logo-list img {
      display: inline-block;
      margin: 14px;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin-bottom: 15px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1.75;
  }

  .footer-secondary {
    border-top: 1px solid #d0d0d0;
    padding: 20px 0;

    p {
      font-size: 16px;
      color: #63626d;
      line-height: 1.44;
    }
  }
}

/* Modal Layout */
/* Variables */
$modal-border-color: #ddd;
$modal-border-radius: 10px;
$button-border-radius: 5px;
$link-color: #0056b3;

/* Modal Layout */
.modal {
  display: none;
  position: fixed;
  z-index: 850;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;

  &-content {
    background-color: #fff;
    padding: 20px;
    border-radius: $modal-border-radius;
    width: 80%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-height: 90vh;
    overflow-y: auto;

    @media (max-width: 665px) {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      padding: 10px;
      border-radius: 0;
    }
  }
}

.btn-close-cookiesettings {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  align-self: flex-end;
  cursor: pointer;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
  }
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    font-size: 1.5em;
    margin: 0;
  }
}

.modal-body {
  margin-bottom: 20px;

  p {
    margin-bottom: 10px;
    line-height: 1.5;
  }

  .more-info-link {
    color: $link-color;
    text-decoration: underline;
    cursor: pointer;
  }
}

.modal-button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  gap: 10px;

  @media (max-width: 665px) {
    justify-content: center;
  }
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .end {
    align-self: flex-end;
  }

  .mainButton {
    background-color: $link-color;
    color: #fff;
    padding: 10px 20px;
    border-radius: $button-border-radius;
    border: none;
    cursor: pointer;
    flex: 1;

    &:last-child {
      margin-right: 0;
    }
  }
}

.cookie-options {
  border: 1px solid $modal-border-color;
  border-radius: $modal-border-radius;
  padding: 0px 10px;

  hr {
    border: 0;
    border-top: 1px solid $modal-border-color;
    margin: 0;
  }

  .text-link {
    color: $link-color;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    text-align: center;
    font-weight: bold;
  }
}

.cookie-option {
  display: flex;
  flex-direction: column;
  padding: 10px 0;

  .cookie-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: white;
    border-radius: $button-border-radius;

    .cookie-label {
      flex: 1;
      margin: 0 10px;
      font-weight: bold;
      cursor: pointer;
    }

    input[type="checkbox"] {
      cursor: pointer;
    }
  }

  .cookie-info {
    display: none;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: $button-border-radius;

    p {
      margin: 0 0 10px 0;
    }
  }

  .more-info-link {
    color: $link-color;
    text-decoration: underline;
    cursor: pointer;
  }
}

.onlyMobile {
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
}

/* checkbox input */
$checkbox-size: 28px;
$checkbox-color: #5cc3ae;

.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: $checkbox-size;
  height: $checkbox-size;
  margin: 0;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    border: 2px solid $checkbox-color;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $checkbox-size * 0.6;
    color: white;
  }

  input:checked + .checkmark {
    background-color: $checkbox-color;
    border-color: $checkbox-color;

    &::after {
      content: "✔";
      display: block;
    }
  }
}

.cursor {
  cursor: pointer;
}

.btn-open-cookiesettings {
  cursor: pointer;
}

/* checkbox input end */

.position-cookie-alert {
  position: fixed;
  bottom: 22px;
  padding: 0 3%;
  left: 0;
  right: 0;
  z-index: 800;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.cookie-alert {
  padding: 14px 16px;
  background: $white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);
  color: $grayDarker;
  font-size: 17px;
  transition: all 0.225s cubic-bezier(0.4, 0, 1, 1);
  line-height: 1.4em;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.055);

  .cookie-buttons > div {
    flex: 1;
    display: flex;
  }

  .cookie-flex {
    .cookie-buttons {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    @include media(">=505px") {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include media("<505px") {
      .cookie-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        flex-direction: column;
      }

      .cookie-buttons > div {
        width: 100%;
      }

      .cookie-buttons .mainButton,
      .btn-open-cookiesettings {
        width: 100%;
        text-align: center;
      }
    }
  }
  &.leaves {
    transform: translateY(80px);
  }

  &.hidden {
    display: none;
  }
}

.mainButton {
  background: $brand-secondary;
  color: $brand-primary;
  cursor: pointer;
  font-weight: $font-weight-bold;
  padding: 6px 16px;
  border-radius: 6px;
  border-bottom: 2px solid $brand-secondary-shadow;
  white-space: nowrap;
}

#update-notice {
  display: none;
  text-align: left;
  padding: 50px 0;

  a {
    color: $brand-secondary;
    text-decoration: none;
  }
}

.no-flexbox #update-notice {
  display: block;
}
