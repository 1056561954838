.blog-hero .front-section {
    @include media(">=desktop") {
        padding: 30px 0;
    }
}

@media (max-width: 767px) {
    .front-section {
        border-bottom: none;
    }
}

.blog-row {
    padding-top: 0;
    // padding-bottom: 0;

    .secbox {
        display: block;
        width: 100%;
    }

    @include media(">=desktop", "height>720px") {
        // padding-bottom: 30px;
    }

    h1 span {
        display: block;
        font-size: 30px;
    }

    .flex-child:last-child {
        @include media(">=tablet") {
            // width: 33.33%;
            flex-basis: 100%;
        }
    }

    img {
        min-height: 1px;
    }

    p.small {
        font-size: 14px;
        color: $brand-primary-light;
        opacity: .6;
        text-align: center;
        padding-top: 1.4em;
        margin-bottom: 0;
    }
}

.blog-headline h3 {
    margin-top: 20px;
}

