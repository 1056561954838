// main: style.scss

.jobs-wrapper {
  background: $brand-primary;
  color: $white;

  a {
    color: $white;
  }
}

.jobs-hero {
  p {
    margin-bottom: 3em;
  }

  // responsive inline svg
  .guru-holder {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;

    @include media("<tablet") {
      padding-top: 1em;
      max-width: 240px;
    }

    @include media(">=tablet") {
      margin-top: -20px;
      margin-right: 0;
    }
  }

  .guru-celebrating {
    width: 100%;
    padding-bottom: 100%;
    position: relative;

    svg,
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.jobs-list-wrapper {
  padding-bottom: 1em;

  @include media(">=tablet") {
    padding-bottom: 5.5em;
  }
}

.jobs-list {
  margin: 0;
  padding: 0;
  list-style: none;

  @include media("<tablet") {
    margin-bottom: 3em;
  }

  li {
    margin-bottom: 12px;
    // background: $brand-secondary;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    // box-sizing: content-box;
    border-bottom: 2px solid $brand-secondary;
    // background: $brand-primary;
    display: block;
    text-decoration: none;
    padding: 12px 0;
    transition: all .2s ease;
    background-image: url("data:image/svg+xml,%3Csvg height='48' viewBox='0 0 48 48' width='48' xmlns='http://www.w3.org/2000/svg'><path d='M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z' fill='#0ffdd9'/></svg%3E");
    background-position: 100% 500%;
    background-size: 35px;
    background-repeat: no-repeat;

    &:hover {
      color: $brand-secondary;
      padding: 10px 0 14px;
      background-position: 100% 50%;
    }
  }
}

.jobs-team {
  @extend %clearfix;
  line-height: 0;

  img {
    max-width: 100%;
    height: auto;
  }

  @include media(">=tablet") {
    position: relative;
    height: 800px;

    .image {
      position: relative;
      width: 50%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;

      &.image-2,
      &.image-3 {
        height: 50%;
        float: right;
      }

      &.image-1 {
        height: 100%;
        float: left;
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      &.object-fit img {
        display: none;
      }
    }
  }

  @include media(">=1600px") {
    height: 1000px;
  }
}

.job-header {
  background: $brand-primary;
  color: #fff;
  margin-bottom: 50px;

  .title {
    max-width: 430px;
    margin-bottom: 1em;
  }

  @include media(">=tablet") {
    .title {
      float: left;
    }

    .badge {
      float: right;
    }

  }
}

.joby-apply-info {
  background: $gray-shallow;
  border: 1px solid darken($gray-shallow, 5%);
  border-radius: 6px;
  padding: 25px;
  margin-top: 4rem;
  margin-bottom: 1em;

  p {
    margin-bottom: 0;
  }
}

.joby-gpg-info {
  font-size: 11px;
  color: $grayLight;
}