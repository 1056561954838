.whistleblow-input {
  width: 100%;
  height: 50px;
  padding: 12px 20px;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 3px;
  margin-bottom: 10px;
  box-shadow: inset 0 0 2px rgba(0,0,0,.15);
  font-size: 16px;
  color: #3c318f;
  font-family: inherit;
}

.whistleblow-input-box {
  width: 100%;
  height: 200px;
  padding: 12px 20px;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 3px;
  margin-bottom: 10px;
  box-shadow: inset 0 0 2px rgba(0,0,0,.15);
  font-size: 16px;
  color: #3c318f;
  font-family: inherit;
}

.whistleblow-input:focus {
  border: 1px solid rgba(0,0,0,.5);
}

.whistleblow-input-box:focus {
  border: 1px solid rgba(0,0,0,.5);
}

label {
  display: block;
  margin-bottom: 10px;
  text-decoration: underline;
}

.whistleblow-form-headline {
  font-size: 120%;
}

.asterisk {
  font-size: 18px;
  line-height: 18px;
  text-align: right;
  color: rgb(219, 42, 58);
}

.whistleblow-disable-button {
  text-decoration: none;
  border: 0;
  padding: 0;
}

.success-visible {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: rgba(51, 199, 88, 0.1);
  color: rgba(51, 199, 88, 1);
  padding: 10px;
  border-radius: 17px;
  align-items: center;
}

.success-hidden {
  display: none;
}

.success-image {
  width: 30px;
  height: 30px;
  background-color: rgba(51, 199, 88, 1);
  border-radius: 60px;
  align-self: center;
  margin: 2px;
  padding: 3px;
}

#btnSubmitWhistleblowForm {
  margin: 30px 0;
  font-size: 20px;
  font-family: inherit;
}