#about-container {
  overflow: hidden;

  @include media(">=tablet") {
    background-image: url("../img/aboutmagic.svg");
    background-position: 50% 0;
    background-repeat: no-repeat;
  }

  .about-intro {
    @include media(">=tablet") {
      text-align: center;
    }

    margin-bottom: 90px;

    h1 {
      margin-bottom: 40px;
      font-size: 54px;
      font-weight: $font-weight-bold;

      @include media("<tablet") {
        font-size: 38px;
        margin-top: 20px;
      }
    }

    .Paragraph {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
      max-width: 720px;
    }

    @include media("<tablet") {
      .btn-group .btn {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .team-container {
    border-top: 1px solid #d0d0d0;

    &.db {
      background: $white;
    }
  }

  .team-intro {
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 60px;

    @include media("<tablet") {
      padding-top: 60px;
      padding-bottom: 45px;
    }
    max-width: 828px;
    @extend .Small-Heading;
    text-align: center;
  }

  .team-hero {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 90px;

    @include media("<tablet") {
      margin-bottom: 45px;
    }
  }

  img.team-hero {
    box-shadow: 0px 0px 20px 0 rgba(0,0,0,0.07);
}

  @include media("<tablet") {
    .team-members {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  @include media(">=tablet") {
    .team-members {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      margin-left: auto;
      margin-right: auto;

      .member {
        padding-left: 15px;
        padding-right: 15px;
        float: left;
        width: 50%;
        flex-basis: 50%;
        overflow: hidden;
      }
    }
  }

  @include media(">=tablet", "<desktop") {
    .team-members {
      max-width: 660px;
    }
  }

  @include media(">=desktop") {
    .team-members {
      justify-content: flex-start;
      margin-left: -15px;
      margin-right: -15px;

      .member {
        box-sizing: content-box;
        width: calc(33.32% - 30px);
        flex-basis: calc(33.32% - 30px);
      }
    }
  }

  .member {
    max-width: 330px;
    margin-bottom: 40px;
    width: 100%;

    .image {
      background-color: $grayLightest;
      padding-bottom: 93%;
      margin-bottom: 20px;
      display: block;
      width: 100%;
      position: relative;
      height: auto;
      border-radius: 3px;
      box-shadow: 3px 5px 11px 0 rgba(80, 74, 103, 0.18);

      img {
        border-radius: 3px;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        display: block;
      }
    }

    .name {
      font-size: 22px;
      font-weight: $font-weight-bold;
    }

    .tag {
      margin-bottom: 12px;
      font-size: 20px;
    }
  }

  .partner {
    margin-bottom: 80px;

    img {
      max-width: 100%;
      height: auto;
    }
    @include media("<tablet") {
      margin-bottom: 0px;

      .image p {
        text-align: center;
        margin-bottom: 2em;
      }
    }

    @include media(">=tablet") {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      .flex {
        flex-basis: 100%;
      }

      .image {
        text-align: center;
      }
    }

    //a {
    //  color: $brand-secondary-shadow;
    //  text-decoration: none;
    //
    //  &:hover {
    //    text-decoration: underline;
    //  }
    //}
  }
}

.hire-banner {
  margin-top: 10px;
  margin-bottom: 100px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border: solid 1px #e5e5e5;
  padding: 15px 25px;
  min-height: 83px;

  @include media(">=tablet") {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

    p {
      margin: 0;
    }
  }
}

.app-info-box {
  border-top: 1px solid #d0d0d0;
  padding: 120px 0;
  position: relative;

  .app-info-box-content {
    @include media(">=tablet") {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;

      .col {
        flex-basis: 50%;
        width: 50%;
        position: relative;
      }

      .phone-big {
        position: absolute;
        top: -118px;
        right: 0px;
        width: 100%;
        z-index: 10;
      }

      .visible .phone-small {
        transform: translateX(0);
      }

      .Small-Heading {
        font-size: 1.8em;
      }
    }
  }

  .Small-Heading {
    margin-bottom: 15px;
  }
}
