// Unser digitales Fort Knox. Serverstandort Deutschland.

// Deine sichere Umgebung im geschützten Bereich.

// Dein Daten-Tresor. Mit unknackbarer AES 256-Bit Verschlüsselung.


// Du behältst die volle Kontrolle
// Mit einem Tipp sind all deine Daten jederzeit löschbar
// Wir speichern keine deiner Bankdaten
.security-hero .front-section {
    @include media(">=desktop") {
        padding: 50px 0;
    }
}

#security-intro {
    padding-top: 0;
    // padding-bottom: 0;

    .secbox {
        display: block;
        width: 100%;
    }

    @include media(">=desktop", "height>720px") {
        // padding-bottom: 50px;
    }

    h1 span {
        display: block;
        font-size: .8em;
    }

    .flex-child:last-child {
        @include media(">=tablet") {
            // width: 33.33%;
            flex-basis: 80%;
        }
    }

    img {
        min-height: 1px;
    }

    p.small {
        font-size: 14px;
        color: $brand-primary-light;
        opacity: .6;
        text-align: center;
        padding-top: 1.4em;
        margin-bottom: 0;
    }
}


// create tour block layout
#security-tour {

    .container {
        margin-bottom: $sectionSpacer;

        @include media(">=tablet") {
            margin-bottom: $sectionSpacerTablet;
        }

        @include media(">=desktop") {
            margin-bottom: $sectionSpacerDesktop;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include media(">=tablet") {
        .flex-child {
            width: 50%;
        }

        .step2 .flex-child:first-child {
            order: 3;
        }
    }

    .svg-holder {
        width: 100%;
        // min-height: 100%;

        @media all and (-ms-high-contrast:none) {
            height: 100%;
        }

        svg {
            max-width: 100%;
            width: 100%;
            display: block;
            height: auto;
            margin: 0 auto;
        }

        @include media("<tablet") {
            max-width: 500px;
            margin: 0 auto;
        }

        #door {
            // max-width: 85%;
        }
    }
}

// bottom three
#security-shorts {



    @include media("<tablet") {
        .container {
            background: $grayLightest;
            max-width: 600px;
            text-align: center;
            padding-top: $sectionSpacer;
            padding-bottom: $sectionSpacer;

            .text-wrapper {
                margin: 0 auto;
            }
        }

        h3 {
            margin-bottom: 3em;
        }

        padding-top: 0px;
    }

    @include media(">=tablet") {
        .container {
            background: $grayLightest;
            padding: 40px 35px;
            border-bottom: 5px solid $brand-primary;
        }

        h3 {
            text-align: center;
            margin-bottom: 2em;
            padding-top: .75em;
        }

        padding-top: 40px;
    }

    h2 {
        font-weight: $font-weight-bold;
        font-size: $fontSizeMobile;
    }

    @include media(">=tablet") {
        .flex-child {
            text-align: center;
        }

        h2 {
            font-size: $fontSizeDesktop;
        }
    }

    .image-holder {
        display: block;
        text-align: center;
        height: 80px;
        position: relative;
        margin-bottom: 1.5em;

        img {
            max-height: 80px;
        }
    }

    .get-notified {
        margin-top: 2em;
        margin-bottom: 1em;
        text-align: center;
    }
}

@include media(">=tablet") {
    #security-tour .step2 .text-wrapper {
        padding-top: 5%;
        // padding-bottom: 15%;
    }

    #security-tour .step2 .step-wrapper {
        padding-bottom: 5%;
    }
}

// @keyframes loopLeftCam {
//     0% {
//         transform: rotateY(0deg);
//     }

//     20% {
//         transform: rotateY(0deg);
//     }

//     50% {
//         transform: rotateY(45deg);
//     }

//     70% {
//         transform: rotateY(45deg);
//     }

//     100% {
//         transform: rotateY(0deg);
//     }
// }

// #cam-left {
//     animation: animationFrames linear 10s;
//     animation-iteration-count: infinite;
//     transform-origin: 50% 50%;
//     // perspective: 600px;
// }
  
// @keyframes animationFrames{
//     0% {
//         transform:  scaleX(1.00) ;
//     }
//     3% {
//         transform:  scaleX(0.7) ;
//     }
//     25% {
//         transform:  scaleX(0.7) ;
//     }
//     28% {
//         transform:  scaleX(1.00) ;
//     }
//     50% {
//         transform:  scaleX(1.00) rotateY(0deg);
//     }
//     56% {
//         transform: rotateY(180deg);
//     }
//     75% {
//         transform: rotateY(180deg);
//     }
//     81% {
//         transform:  scaleX(1.00) ;
//     }
//     100% {
//         transform:  scaleX(1.00) ;
//     }
// }

// #cam-right {
//     animation: animationFramesRight linear 10s;
//     animation-iteration-count: infinite;
//     transform-origin: 50% 50%;
//     // perspective: 600px;
// }
  
// @keyframes animationFramesRight {
//     0% {
//         transform:  scaleX(1.00) rotateY(0deg);
//     }
//     6% {
//         transform: rotateY(180deg);
//     }
//     25% {
//         transform: rotateY(180deg);
//     }
//     31% {
//         transform:  scaleX(1.00) ;
//     }
//     50% {
//         transform:  scaleX(1.00) ;
//     }
//     53% {
//         transform:  scaleX(0.7) ;
//     }
//     75% {
//         transform:  scaleX(0.7) ;
//     }
//     78% {
//         transform:  scaleX(1.00) ;
//     }
//     100% {
//         transform:  scaleX(1.00) ;
//     }
// }