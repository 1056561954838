//main: style.scss

////////////////////
// Table of Contents
//
// 01. breakpoints and grid system
// 02. colors
// 03. typography
// 04. borders
// 05. overlay

/////////////////////////
// 01. breakpoints and grid system

// media breakpoints
$breakpoints: (
	mobile: 320px,
	tablet: 768px,
	desktop: 1024px
);

// grid-system
// TODO: Eliminate these
$container-padding-mobile: 30px;
$container-width: 960px;
$col-padding: 30px;

// flex grid system
$sectionSpacer: 80px;
$sectionSpacerTablet: 110px;
$sectionSpacerDesktop: 120px;
$sectionSpacerVertical: 60px;

$colSpacingTablet: 40px;
$colSpacingDesktop: 60px;



/////////////////////////
// 02. colors

// grayscale
$grayDark: #7e7e7e; // only used for Recoms Header Label
$grayDarker: #636363; // used for dark text
$grayLight: #aaaaaa; // used for lighter text
$grayLighter: #dbdbdb; // used as bottom shaddow for white objects
$grayLightest: #F3F3F3; // used as border color and seperator-backgroud for dark text
$grayBright: #fafafa; // used as background color for header, tabbar, settings pages. Rare use.

// brand colors
$brand-primary-bright: 	  #AEAAD0;
$brand-primary-light: 	  #908ac2;
$brand-primary:           #3c318f;
$brand-primary-shadow:    #26266e;
$brand-secondary:         #0ffdd9;
$brand-secondary-shadow:  #18c5ab;
$brand-secondary-dark:    #17C4AB;

$brand-genius-dark: #1F1F34;
$brand-genius-light: #8F35F3;

// grayscale
// TODO: eliminate these
$white:						#fff;
$gray-light:				#9d9d9c;
$gray-shallow:				#ededed;




/////////////////////////
// 03. typography

// set font families
$font-family-base: "sofia-pro", 'Sofia Pro', 'Lato', 'Avenir', Arial, sans-serif;
$font-weight-regular: 300; //"sofia-pro-n3", "sofia-pro"
$font-weight-bold: 500; //"sofia-pro-n5", "sofia-pro"

/* Color palette */

:root {
	--blueberry: #3c318f;
	--bright-aqua: #0efdd8;
	--topaz: #18c5ab;
}

/* Text styles */

.Paragraph {
	font-family: $font-family-base;
	font-size: 20px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

.Paragraph-Small {
	font-family: $font-family-base;
	font-size: 16px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #63626d;
}

.Paragraph-Huge {
	font-family: $font-family-base;
	font-size: 23px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

.Frontpage-Heading {
	font-family: $font-family-base;
	font-size: 38px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;

	@include media("<500px") {
		font-size: 32px;
	}
}

.Nav-Link-Desktop {
	font-family: $font-family-base;
	font-size: 18px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

.Small-Heading {
	font-family: $font-family-base;
	font-size: 26px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;

	@include media("<tablet") {
		font-size: 22px;
		line-height: 1.44;
	}
}

.Footer-Linklist {
	font-family: $font-family-base;
	font-size: 16px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

// Add important font sizes for frontpage
$fontSizeMobile: 20px;
$fontSizeDesktop: 20px;

$fontSizeSmallMobile: 16px;
$fontSizeSmallDesktop: 18px;

// TODO: eliminate these
// set font sizes
$font-size-base: 18px;
$font-size-big: 1.222rem;
$font-size-bigger: 2em;
$font-size-huge-mobile: 2.777rem;
$font-size-huge: 3.25rem;

$font-size-nav-mobile: 2.3em;

/////////////////////////
// 04. borders

$border-radius: 5px;

/////////////////////////
// 05. overlay settings

// overlay
$mfp-overlay-color:	#000;
$mfp-overlay-opacity:	0.1;
// spacing
$mfp-popup-padding-left: 0;
$mfp-popup-padding-left-mobile: 0;
$mfp-z-index-base: 1040; // Base z-index of popup
// controls
$mfp-include-arrows: false;
// Iframe-type options
$mfp-include-iframe-type: true;
// Image-type options
$mfp-include-image-type: false;
$mfp-include-mobile-layout-for-image: false;
