//main: style.scss

////////////////////
// Table of Contents
//
// 01. functional classes
// 02. base styling of font and colors
// 03. create buttons
// 04. create grid system
// 05. social icon grid
// 06. phone frame system
// 07. modal

/////////////////////////
// 01. functional classes

*,
*::before,
*::after {
  box-sizing: border-box;
}

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}

html,
body {
  height: 100%;
  width: 100%;
  position: relative;
  background: $grayBright;
}

.fix {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/////////////////////////
// 02. base styling of font and colors

body {
  color: $brand-primary;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
  margin: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  font-weight: $font-weight-bold;
}

h1 {
  font-size: $font-size-bigger;

  @include media(">=tablet") {
    font-size: $font-size-huge;
  }
}

h2 {
  font-size: $font-size-big;
  margin-bottom: 1em;
}

p {
  margin-top: 0;
  line-height: 1.35em;

  @include media(">=tablet") {
    line-height: 1.45em;
  }
}


ul {
  line-height: 1.35em;
  padding-left: 22px;

  @include media(">=tablet") {
    line-height: 1.45em;
  }

  li {
    margin-bottom: 0.4em;
  }
}

ol {
  line-height: 1.35em;
  padding-left: 22px;

  @include media(">=tablet") {
    line-height: 1.45em;
  }

  li {
    margin-bottom: 0.4em;
  }
}


a {
  color: $brand-secondary-shadow;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $brand-secondary-shadow;
    text-decoration: underline;
  }
}

.text-center {
  text-align: center;
}

.flag {
  display: inline-block;
  width: 1em;
  height: 1em;
  padding-bottom: 1px;
}

.entry {
  ul {
    border-top: 1px solid $gray-shallow;
    list-style: none;
    padding: 0;
    margin-bottom: 2em;

    li {
      border-bottom: 1px solid $gray-shallow;
      padding: 12px 12px 12px 25px;
      position: relative;
      line-height: 1.4em;

      &::before {
        position: absolute;
        top: 10px;
        left: 10px;
        content: '•';
      }

      &:nth-child(even) {
        background: lighten($gray-shallow, 5%);
      }
    }
  }

  h2 {
    margin-bottom: 2em;
  }
}

label {
  text-decoration: none;
}

#datenschutzCheckbox {
  width: 40px;
  height: 40px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

/////////////////////////
// 03. create buttons

button.btn {
  border: 0;
}

// basic styling
.btn {
  // btn style
  border-radius: 5px;
  padding: 9px 20px;

  background-color: #f2f2f2;
  box-shadow: 0 2px 4px 0 #d5d5d5;
  color: $brand-secondary-shadow;

  // font style
  font-size: 18px;
  font-weight: 500;
  text-align: center;

  // functional styles
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: all .15s ease-in-out;

  &.white {
    background-color: #fff;
    width: 230px;
    text-align: left;
  }

  &.big {
    padding: 12px 28px;
    font-size: 20px;
  }

  &.whatsapp {
    background-color: #fff;
    padding: 5px 12px 2px 8px;
  }

  &:hover {
    text-decoration: none;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px 0 #d5d5d5;
    color: $brand-secondary-shadow;
  }

  &.btn-secondary {
    background-color: $brand-secondary;
    box-shadow: 0 2px 4px 0 $brand-secondary-shadow;
    color: $brand-primary;

    &:hover {
      box-shadow: 0 4px 6px 0 $brand-secondary-shadow;
      color: $brand-primary;
    }
  }

  &:active {
    transform: translateY(1px);
  }

  &.btn-disabled {
    cursor: not-allowed;
    color: #aeaeae;
    background: #d5d5d5;
    box-shadow: 0px .25em 0px 0px #cacaca;

    &:hover,
    &:active {
      transform: none;
    }
  }
}

.btn-group {
  margin-left: -10px;
  margin-right: -10px;
  &.btn-group-fix {
    margin-left: 0;
  }
  .btn {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.video-link {
  background-image: url('../img/play.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 40px;
  padding-right: 15px;
  background-size: 24px 24px;
}

// create spinner
$dotSize: 12px;

.spinner {
  width: ($dotSize * 3 + 16);
  text-align: center;
  margin-top: 9px;
}

.spinner > div {
  width: $dotSize;
  height: $dotSize;
  background-color: $brand-primary;

  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}

/////////////////////////
// 04. create grid system

// structure is .container > .row > .col-x + .col-y;

.container {
  @extend %clearfix;
  max-width: $container-width;
  margin-left: auto;
  margin-right: auto;
}

.row {
  @extend %clearfix;
}

// every container has mobile spacing
@include media("<tablet") {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .hide-mobile {
    display: none;
  }
}

// tablet columns

@include media(">=tablet") {
  .container {
    padding-left: calc($col-padding / 2);
    padding-right: calc($col-padding / 2);
    width: 95%;
  }

  .row {
    margin-left: -(calc($col-padding / 2));
    margin-right: -(calc($col-padding / 2));
  }

  .col-1 {

    float: left;
    padding-left: calc($col-padding / 2);
    padding-right: calc($col-padding / 2);
    width: 16.666%;
  }

  .col-2 {
    float: left;
    padding-left: calc($col-padding / 2);
    padding-right: calc($col-padding / 2);
    width: 33.333%;
  }

  .col-3 {
    float: left;
    padding-left: calc($col-padding / 2);
    padding-right: calc($col-padding / 2);
    width: 50%;
  }

  .col-4 {
    float: left;
    padding-left: calc($col-padding / 2);
    padding-right: calc($col-padding / 2);
    width: 66.666%;
  }

  .col-5 {
    float: left;
    padding-left: calc($col-padding / 2);
    padding-right: calc($col-padding / 2);
    width: 83.333%;
  }

  .col-6 {
    float: left;
    padding-left: calc($col-padding / 2);
    padding-right: calc($col-padding / 2);
    width: 100%;
  }

  .push-right {
    float: right;
  }

  .hide-tablet {
    display: none;
  }
}

@include media(">=desktop") {
  .container {
    width: 90%;
  }

  .row-lg {
    margin-left: -(calc($col-padding / 2));
    margin-right: -(calc($col-padding / 2));
  }

  .col-lg-2 {
    float: left;
    padding-left: calc($col-padding / 2);
    padding-right: calc($col-padding / 2);
    width: 33.333%;
  }

  .col-lg-4 {
    float: left;
    padding-left: calc($col-padding / 2);
    padding-right: calc($col-padding / 2);
    width: 66.666%;
  }
}

/////////////////////////
// 05. social icons
.sicon {
  $iconCount: 5;
  $iconSize: 40px;
  display: inline-block;
  height: ($iconSize + 1);
  width: $iconSize;
  background-image: url('../img/social-icons.svg');
  background-size: ($iconSize * $iconCount);
  background-position-y: 0;
  background-repeat: no-repeat;
  transition: all .225s ease-out;

  &:hover {
    opacity: .8;
    transform: rotate(-5deg);
  }

  .sicons-white & {
    background-position-y: -$iconSize;
  }

  &.facebook {
    background-position-x: 0;
  }

  &.tiktok {
    background-position-x: -($iconSize * 1);
  }

  &.mail {
    background-position-x: -($iconSize * 2);
  }

  &.youtube {
    background-position-x: -($iconSize * 3);
  }

  &.instagram {
    background-position-x: -($iconSize * 4);
  }
}

/////////////////////////
// 06. contact badge

.contact-badge {
  background: $white;
  color: $brand-primary;
  border-radius: 6px;
  padding: 20px 20px 0;
  @extend %clearfix;
  max-width: 400px;
  border-bottom: 5px solid #b2b2b2;
  margin-bottom: 2em;
  // line-height: 1em;

  .image {
    width: 90px;
    border-radius: 100%;
    background: $gray-shallow;
    height: 90px;
    background-image: url('../img/contact-badge-friederike.png');
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .content {
    margin-bottom: 20px;
  }

  .content-job {
    margin: 0 50px 20px 40px; 
  }

  &.benni .image {
    background-image: url('../img/contact-badge-benni.png');
  }

  &.lisa .image {
    background-image: url('../img/contact-badge-lisa.png');
  }

  span {
    display: block;
  }

  .title {
    font-size: 13px;
    opacity: .5;
    text-transform: uppercase;
    margin-bottom: .2em !important;
    float: none !important;
  }

  .name {
    font-weight: $font-weight-bold;
    margin-bottom: .2em;
    font-size: $font-size-big;
  }

  .mail {
    margin-bottom: .2em;
  }

  a {
    color: $brand-primary;
    text-decoration: none;

    &:hover {
      color: $brand-secondary-shadow;
    }
  }

  @include media("<375px") {
    .image {
      margin-bottom: .5em;
    }
  }

  @include media(">=tablet", "<1200px") {
    .image {
      margin-bottom: .5em;
    }
  }

  @include media(">=375px", "<tablet") {
    .image {
      float: left;
    }

    .content {
      float: left;
    }
  }

  @include media(">=850px") {
    .image {
      float: left;
    }

    .content {
      float: left;
    }
  }
}

.de-flag {
  display: inline-block;
  background: #ff0000;
  height: .2em;
  width: .8em;
  position: relative;
  vertical-align: middle;
  margin-right: 7px;

  &::before,
  &::after {
    content: ' ';
    height: .2em;
    width: .8em;
    position: absolute;
    left: 0;
  }

  &::before {
    top: -.2em;
    background: black;
  }

  &::after {
    bottom: -.2em;
    background: #fee50f;
  }
}

////////////////
// 06. phone frame system

.phone-holder {
  position: relative;
  width: 100%;
  padding-bottom: 174.52%;
  background-image: url('../img/smartphone-frame.png');
  background-size: contain;

  .notch {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 68.45%;
    height: 80.76%;
    background-image: url('../img/smartphone-x-overlay.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 62.26%;
    height: 77.21%;
    border-radius: 10px;
    overflow: hidden;

    .cover {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .videoscreen {
      position: absolute;
      z-index: 50;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

/**
 * Fade-zoom animation for first dialog
 */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;



  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

