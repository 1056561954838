.ng-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ng-col {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ng-pagetitle {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.ng-pagetitle,
.ng-title {
  font-family: var(--font-sofia-semibold);
  font-weight: 600;
  letter-spacing: -0.025em;
}

.ng-title {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.ng-subtitle {
  font-family: var(--font-sofia-semibold);
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: -0.025em;
}

.ng-h-\[124px\] {
  height: 124px;
}

.ng-h-\[2px\] {
  height: 2px;
}

.ng-h-\[42px\] {
  height: 42px;
}

.ng-w-1\/2 {
  width: 50%;
}

.ng-w-full {
  width: 100%;
}

.ng-max-w-\[100px\] {
  max-width: 100px;
}

.ng-max-w-\[300px\] {
  max-width: 300px;
}

.ng-max-w-none {
  max-width: none;
}

.ng-max-w-screen-md {
  max-width: 768px;
}


.ng-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ng-flex-1 {
  flex: 1 1 0%;
}

.ng-items-start {
  align-items: flex-start;
}

.ng-items-end {
  align-items: flex-end;
}

.ng-items-center {
  align-items: center;
}


.ng-gap-lg {
  gap: 2rem;
}

.ng-gap-md {
  gap: 1.25rem;
}

.ng-gap-sm {
  gap: 1rem;
}

.ng-gap-xs {
  gap: 0.5rem;
}

.ng-self-center {
  align-self: center;
}

.ng-rounded-full {
  border-radius: 9999px;
}

.ng-rounded-lg {
  border-radius: 1.0625rem;
}

.ng-rounded-md {
  border-radius: 0.75rem;
}

.ng-border-2 {
	border-style: solid;
  border-width: 2px;
}

.ng-border-accent {
  border-color: #ab20fe;
}

.ng-bg-accent {
  background-color: #ab20fe;
}

.ng-bg-secondaryBackground {
  background-color: #fff;
}

.ng-bg-separator {
  background-color: rgba(74, 63, 130, 0.13);
}

.ng-bg-tertiaryBackground {
  background-color: #f2f1f6;
}

.ng-p-1 {
  padding: 0.25rem;
}

.ng-p-md {
  padding: 1.25rem;
}

.ng-p-sm {
  padding: 1rem;
}

.ng-pb-lg {
  padding-bottom: 2.25rem;
}

.ng-text-center {
  text-align: center;
}

.ng-text-accent {
  color: #ab20fe;
}

.ng-text-caption {
  color: #8e8e93;
}

.ng-text-white {
  color: #fff;
}


@media (min-width: 768px) {
  .md\:ng-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .md\:ng-min-w-\[280px\] {
    min-width: 280px;
  }

  .md\:ng-justify-between {
    justify-content: space-between;
  }

  .md\:ng-self-center {
    align-self: center;
  }

  .md\:ng-text-left {
    text-align: left;
  }

  .md\:ng-flex {
    display: flex !important;
  }

  .md\:ng-hidden{
    display: none !important;
  }
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}
.flex {
  display: flex;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.rounded-md {
  border-radius: 0.375rem;
}
.border-0 {
  border-width: 0px;
}
.border-2 {
  border-width: 2px;
}
.border-transparent {
  border-color: transparent;
}
.bg-transparent {
  background-color: transparent;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.font-medium {
  font-weight: 500;
}
.opacity-50 {
  opacity: 0.5;
}
.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}


.group:hover .group-hover\:flex {
  display: flex;
}
.hidden {
  display: none;
}
[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.myinput::-webkit-outer-spin-button,
.myinput::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.myinput[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

