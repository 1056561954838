#get-app {
  @include media(">=tablet") {
    margin-bottom: 30px;
  }
  .text-wrapper {
    text-align: left;
    max-width: 490px;
    margin: 0 auto;
  }

  @include media(">=tablet") {
    #get-smartphone {
      margin-top: -50px;
    }
  }

  h2 {
    font-weight: $font-weight-bold;
    font-size: 1.6em;
    line-height: 1.3em;
  }

  p {
    line-height: 1.4em;
  }
  
  .front-store-buttons-wrapper {
    float: left;
    margin-bottom: 30px;
  }
  
  .input-row {
    display: flex;
    max-width: 340px;
  }

  .alert {
    max-width: 340px;
    margin-top: 10px;
  }

  @include media(">=375") {
    .button img,
    .button {
      max-width: none;
    }
  }

  @include media(">=tablet") {
    .button {
      width: 100%;
    }
  }

  #mobileinput {
    background: $grayLightest url('../img/de.svg') no-repeat 15px center;
    background-size: 20px 12px;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    border: 1px solid $grayLighter;
    padding: 0px 15px 2px 47px;
    display: block;
    width: 100%;
    font-size: 1.1em;
    text-align: left;
    margin-bottom: 6px;
    font-family: $font-family-base;
    font-weight: $font-weight-regular;
    color: $brand-primary;
    height: 52px;
  }

  #submit {
    padding: 0px 18px 2px 18px;
    margin-bottom: 6px;
    border-radius: 16px;
    font-size: 1.1em;
    margin-left: -16px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    span {
      display: block;
    }
  }

  .input-hint {
    margin-left: 13px;
  }

  .hint {
    color: $grayLight;
    margin-bottom: 30px;
    font-size: 14px;
  }

  .smaller-heading {
    margin-bottom: 5px;
    font-weight: $font-weight-bold;
  }
  
  #qrcode {
    float: left;
    margin-right: 15px;
  }
}


// animate loading button
.fade-enter-active {
  transition: opacity .375s
}
.fade-leave-active {
  transition: opacity .125s
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}


.alert,
.response {
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 6px;
}

.alert-error,
#mce-error-response {
  border-radius: .25rem;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bcdff1;
  color: #31708f;
}


.alert-success,
#mce-success-response {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb
}

#loginhelp {
  margin-bottom: 30px;
  .text-wrapper-custom {
    text-align: left;
    max-width: 700px;
    margin: 0 auto;
  }

  h1 {
    font-size: 40px;
    line-height: 1.25;
    font-weight: $font-weight-bold;
    letter-spacing: .008em;
    margin-bottom: 1.25em;
  }

  .teaser {
    font-size: 1.25em;
  }

  .step {

    ol {
      li {
        margin-bottom: .3em;
        line-height: 1.45em;
      }
    }
    @extend %clearfix;

    @include media(">=tablet") {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;

      .step-number {
        margin-right: 30px;
      }
    }

    padding-bottom: 50px;

    &.divider {
      border-bottom: 1px solid $grayLighter;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    .step-number {
      margin-bottom: 20px;
      font-weight: $font-weight-bold;
      font-size: 2em;
      border: 2px solid $brand-primary;
      display: flex;
      border-radius: 100%;
      width: 60px;
      height: 60px;
      justify-content: center;
      align-items: center;
      flex-basis: 60px;
      min-width: 60px;

      span {
        padding-bottom: 5px;
      }
    }

    h2 {
      display: block;
      padding-top: 12px;
      font-size: 28px;
      line-height: 1.4;
      font-weight: $font-weight-bold;
      letter-spacing: .012em;
    }

  }
}