// main: ../style.scss
/* Magnific Popup CSS */

////////////////////////
//      Settings      //
////////////////////////

// overlay
$mfp-overlay-color:                   #000000 !default;                    // Color of overlay screen
$mfp-overlay-opacity:                 0.8 !default;                        // Opacity of overlay screen
$mfp-shadow:                          0 0 8px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe

// spacing
$mfp-popup-padding-left:              8px !default;                        // Padding from left and from right side
$mfp-popup-padding-left-mobile:       6px !default;                        // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base:                    1040 !default;                       // Base z-index of popup

// controls
$mfp-include-arrows:                  false !default;                       // Include styles for nav arrows
$mfp-controls-opacity:                0.65 !default;                       // Opacity of controls
$mfp-controls-color:                  #FFF !default;                       // Color of controls
$mfp-controls-border-color:           #3F3F3F !default; 	                 // Border color of controls
$mfp-inner-close-icon-color:          #333 !default;                       // Color of close button when inside
$mfp-controls-text-color:             #CCC !default;                       // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       #FFF !default;                       // Hover color of preloader and "1 of X" indicator

// Iframe-type options
$mfp-include-iframe-type:             true !default;                       // Enable Iframe-type popups
$mfp-iframe-padding-top:              40px !default;                       // Iframe padding top
$mfp-iframe-background:               #000 !default;                       // Background color of iframes
$mfp-iframe-max-width:                900px !default;                      // Maximum width of iframes
$mfp-iframe-ratio:               calc(9/16) !default;                       // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type:              false !default;                       // Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               40px !default;                       // Image padding top
$mfp-image-padding-bottom:            40px !default;                       // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default;                       // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color:             #F3F3F3 !default;                    // Caption title color
$mfp-caption-subtitle-color:          #BDBDBD !default;                    // Caption subtitle color

// A11y
$mfp-use-visuallyhidden:              false !default;                      // Hide content from browsers, but make it available for screen readers

////////////////////////
//
// Contents:
//
// 1. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 2. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////



////////////////////////
// 1. General styles
////////////////////////

// Transluscent overlay
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 2;
  overflow: hidden;
  position: fixed;

  background: $mfp-overlay-color;
  opacity: $mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 3;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 $mfp-popup-padding-left;
  box-sizing: border-box;
}

// Vertical centerer helper
.mfp-container {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

// @include media("<tablet") {
// // Remove vertical centering when popup has class `mfp-align-top`
//   .mfp-align-top {
//     .mfp-container {
//       &:before {
//         display: none;
//       }
//     }
//   }
// }

// Popup content holder
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: $mfp-z-index-base + 5;
  width: 100%;
}

.mfp-hide {
  display: none !important;
}

button {
  &.mfp-close,
  &.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: $mfp-z-index-base + 6;
    box-shadow: none;
    touch-action: manipulation;
  }
  &::-moz-focus-inner {
      padding: 0;
      border: 0
  }
}


// Close icon
.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;

  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: $mfp-controls-opacity;
  padding: 0 0 18px 10px;
  color: $mfp-controls-color;

  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &:active {
    top: 1px;
  }
}

// Iframe content type
@if $mfp-include-iframe-type {
  .mfp-iframe-holder {
    padding-top: $mfp-iframe-padding-top;
    padding-bottom: $mfp-iframe-padding-top;
    .mfp-content {
      line-height: 0;
      width: 100%;
      max-width: $mfp-iframe-max-width;
    }

    .mfp-close {
      color: $mfp-controls-color;
      right: -6px;
      text-align: right;
      padding-right: 6px;
      width: 100%;
      top: -40px;
    }
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: $mfp-iframe-ratio * 100%;
    iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: $mfp-shadow;
      background: $mfp-iframe-background;
    }
  }
}


