// main: style.scss

/**
stylize sticky notice (placed above header)
 */
#sticky-notice {
  background-color: $brand-secondary-shadow;
  border-bottom: 1px solid $grayLighter;
  color: $white;
  text-align: center;
  font-size: 1rem;
  padding: 8px 10px;
  line-height: 1.45em;

  .bold {
    font-weight: $font-weight-bold;
  }
}

.header__sticky__placeholder {
  display: none;
  .fixed-header & {
    display: block;
  }
  @include media("<tablet") {
    height: 60px;
  }

  @include media(">=tablet") {
    height: 65px;
  }

  .page-primary-header & {
    background-color: $brand-primary;
  }
}

/**
stylize header
 */
.header {
  z-index: 500;
  left: 0;
  top: 0;
  right: 0;
  position: relative;
  .fixed-header & {
    position: fixed;
  }
  .fix & {
    bottom: 0;
  }
  background-color: $grayBright;

  // set backgroundColors
  .backdropfilter & {
    backdrop-filter: saturate(100%) blur(25px);
    background: rgba(250, 250, 250, 0.8);
    border-bottom: 1px solid transparent;
  }
  .backdropfilter .fixed-styles & {
    border-bottom-color: $grayLightest;
  }.backdropfilter .fixed-styles.page-primary-header & {
    border-bottom-color: $brand-primary-shadow;
  }
  .no-backdropfilter & {
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0);
  }
  .no-backdropfilter .fixed-styles & {
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.17);
  }
  .page-primary-header & {
    background-color: $brand-primary;
  }

  /**
  inner header container
   */
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media("<tablet") {
      height: 60px;
    }

    @include media(">=tablet") {
      height: 65px;
    }
  }

  /**
  left header content
   */
  .title {
    font-weight: $font-weight-bold;
    margin: 0;

    font-size: 1.43rem;
    @include media("<375px") {
      font-size: 1.2em;
    }
    @include media(">=tablet") {
      font-size: 1.75rem;
    }

    a {
      color: $brand-primary;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .page-primary-header & {
      background-color: $brand-primary;
      color: $white;

      a {
        color: $white;
      }
    }

    .beta-badge {
      padding: 5px 12px;
      display: inline-block;
      border-radius: 6px;
      background-color: rgba(24, 197, 171, 0.1);
      color: $brand-secondary-shadow;
      font-size: 18px;
      font-weight: $font-weight-bold;

      .page-primary-header & {
        color: $brand-secondary;
      }
      
      @include media("<375px") {
        display: none;
      }
      
      @include media(">=375px", "<414px") {
        .fixed-styles & {
          opacity: 0;
          width: 1px;
          overflow: hidden;
        }
      }

      @include media(">=tablet") {
        margin-top: 1px;
        font-size: 23px;
      }
    }
  }

  /**
  right header content menu toggle
   */
  .menu-toggle {
    z-index: 750;
    position: relative;
    display: flex;
    align-items: center;
    @include media(">=tablet") {
      display: none !important;
    }
    
    .dl-link {
      display: none;
      font-weight: $font-weight-bold;

      .page-primary-header & {
        a {
          color: $brand-secondary;
        }
      }
      
      .fixed-styles & {
        display: block;
        animation-name: fadeInUp;
        animation-duration: .4s;
        animation-timing-function: ease-in-out;
      }
    }

    .menu-toggle-icon {
      width: 60px;
      @include media("<375px") {
        width: 45px;
      }
      height: 50px;
      position: relative;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 31px;
        background: $brand-secondary-shadow;
        border-radius: 3px;
        right: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        .page-primary-header & {
          background: $brand-secondary;
        }

        &:nth-child(1) {
          top: 18px;
        }

        &:nth-child(2) {
          top: 28px;
        }
      }

      &.open {
        span {
          background: $white;

          &:nth-child(1) {
            top: 23px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            top: 23px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  /**
  right header navigation
   */
  .menu {
    /*
    mobile navigation
     */
    @include media("<tablet") {
      background: $brand-primary;
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 700;
      text-decoration: none;
      overflow: hidden;

      &.open {
        display: block;
        animation-name: showMenu;
        animation-duration: .225s;

        .menu-items {
          animation-name: fadeInUp;
          animation-duration: .4s;
          animation-timing-function: ease-in-out;
        }
      }

      ul {
        padding: 10% 0 0 15%;
        list-style: none;
      }

      .signup-holder {
        margin-top: 1em;
      }

      li:not(.signup-holder) {
        font-size: 32px;
        font-weight: $font-weight-bold;
        line-height: 1.7em;

        a {
          color: $white;
          text-decoration: none;
        }
      }

      #scrollable {
        height: 100%;
        // height: 100vh;
        overflow: auto;
      }

      .sicons-white {
        margin-top: 2em;

        .whatsapp {
          line-height: 1;
          margin-bottom: 1em;
        }
      }
    }
    /*
    desktop navigation
     */
    @include media(">=tablet") {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li {
        float: left;
      }

      a {
        display: inline-block;
        text-decoration: none;
        padding: 9px 12px;
        color: $brand-primary;
        &:hover {
          color: $brand-secondary-shadow;
        }

        &.btn {
          margin-left: 10px;
          padding: 9px 15px;
        }

        &.dl-link {
          font-weight: $font-weight-bold;
          color: $brand-secondary-shadow;
        }

        .page-primary-header & {
          &:not(.btn) {
            color: $white;

            &:hover {
              color: $brand-secondary;
            }
          }
        }
      }
    }
  }
}

// mobile menu blend background
@keyframes showMenu {
  from {
    opacity: 0;
    border-radius: 100%;
    transform: translate3d(90%, -90%, 0) scale(0);
  }

  to {
    border-radius: 0;
    opacity: 1;
    transform: none;
  }
}

// mobile menu blend text
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 2%, 0);
  }

  50% {
    opacity: 0;
    transform: translate3d(0, 2%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

// create sticky header
@include media(">=tablet") {
  .fixed-styles {
    .menu li:not(.signup-holder) {
      display: none;
    }

    &.scroll-up {
      .menu li:not(.signup-holder) {
        display: block;
        animation: simpleFadeIn;
        animation-duration: .375s;
      }
    }
  }
}

/**
create heading spacer
 */
.header-spacer {
  box-sizing: content-box;
  .page-primary-header & {
    background-color: $brand-primary;
  }

  @include media("<tablet") {
    padding-bottom: 30px;
  }

  @include media(">=tablet", "height<600px") {
    padding-bottom: 30px;
    padding-top: 10px;
  }

  @include media(">=tablet", "height>=600px") {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  @include media(">=tablet", "height>=750px") {
    padding-bottom: 45px;
  }

  @include media(">=tablet", "height>=1000px") {
    padding-bottom: 80px !important;
  }

  .home.test-screen & {
    padding-bottom: 10px;
    padding-top: 0;
  }
}

// mobile menu blend text
@keyframes headerIn {
  0% {
    transform: translate3d(0, -100px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

// mobile menu blend text
@keyframes simpleFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}